import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Container } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { NewsSliceZone } from '../components/NewsSliceZone'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { defaultLanguage } from '../../prismic-configuration'

const NewsTemplate = ({ data }) => {
  if (!data) return null

  const newsContent = data.prismicNews
  const news = newsContent.data || {}
  const tags = newsContent.tags || {}

  const { lang, type, url } = newsContent
  const alternateLanguages = newsContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  let postDate = news.custom_publication_date
  const image = getImage(news.main_image.gatsbyImageData)
  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`

  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} isHomeNews={false}>
      <Seo title={news.page_title.text} lang={lang} />
      <Container className="single-page_wrapper">
        <Link
          to={`${localLinkResolver}/all-news`}
          className="mt-2 btn btn-outline-secondary"
        >
          back
        </Link>
        <div className="single-news_img-wrapper">
          <GatsbyImage image={image} alt={news.page_title.text} />
        </div>
        <div className="single-news-title">
          <h1>{news.page_title.text}</h1>
          <div>{postDate}</div>
        </div>
        <hr />
        <br />
        <NewsSliceZone newsSliceZone={news.body} />
        <div className="tags-wrapper">
          <hr />
          {tags &&
            tags.map((tag, key) => {
              return (
                <Link
                  key={key}
                  to={`${localLinkResolver}/all-news`}
                  className="tags btn btn-outline-secondary"
                  // onClick={() => setActiveTag(tag)}
                >
                  {tag}
                </Link>
              )
            })}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query NewsQuery($id: String, $lang: String) {
    prismicNews(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
          raw
        }
        custom_publication_date(formatString: "DD/MM/YYYY")
        main_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...NewsDataBodyText
          ...NewsDataBodyTable
          ...NewsDataBodyQuote
          ...NewsDataBodyFullWidthImage
          ...NewsDataBodyImageGallery
          ...NewsDataBodyImageHighlight
        }
      }
      tags
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(NewsTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
